import { useFetcher } from "@remix-run/react";
import { useCallback, useEffect, useState } from "react";
import ReCaptcha from "react-google-recaptcha";
import { ClientOnly } from "remix-utils/client-only";
import { AuthenticityTokenInput } from "remix-utils/csrf/react";
import { ValidatedForm } from "remix-validated-form";

import { Modal } from "~/components/Modal";
import { Button } from "~/components/ui/button";
import { ValidatedInput } from "~/components/ui/forms/input";
import { useManagedFetcher } from "~/hooks/use-managed-fetcher";
import { forgottenPasswordEmailValidator } from "~/validators/forgotten-password-email";

import { Typo } from "../Typo";

export function ForgottenPasswordModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: (val: boolean) => void;
}) {
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState("");

  const fetcher = useFetcher<any>();
  const [isFetcherLoading, isFetcherDone] = useManagedFetcher({
    fetcher,
  });

  const closeFn = useCallback(
    (val: boolean) => {
      setWasSubmitted(false);
      onClose(val);
    },
    [onClose]
  );

  useEffect(() => {
    if (isFetcherDone && wasSubmitted) {
      closeFn(false);
    }
  }, [isFetcherDone, closeFn, wasSubmitted]);

  useEffect(() => {
    if (isFetcherLoading) {
      setWasSubmitted(true);
    }
  }, [isFetcherLoading]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose(false)}
      className="ForgottenPasswordModal-modal"
      title="Mot de passe oublié"
      size="md"
    >
      <ValidatedForm
        validator={forgottenPasswordEmailValidator}
        fetcher={fetcher}
        method="POST"
        action="/reinitialiser-mot-de-passe"
        className="flex flex-col gap-6"
      >
        <input type="hidden" name="_action" value="sendForgottenPasswordEmail" />
        <AuthenticityTokenInput />

        <Typo>
          Entrez votre adresse email de connexion et nous vous enverrons un email contennant un lien
          pour réinitialiser votre mot de passe.
        </Typo>
        <ValidatedInput
          label="Email"
          name="email"
          id="email"
          errorMessage={fetcher.data?.fieldErrors?.email}
        />
        <ClientOnly fallback={null}>
          {() => (
            <ReCaptcha
              sitekey={window?.ENV?.RECAPTCHA_SITE_KEY ?? ""}
              onChange={(token) => setRecaptchaToken(token || "")}
            />
          )}
        </ClientOnly>
        <ValidatedInput type="hidden" id="recaptcha" name="recaptcha" value={recaptchaToken} />
        <Button
          type="submit"
          loadingState={
            fetcher.state === "submitting" || fetcher.state === "loading" ? "loading" : "idle"
          }
        >
          Confirmer
        </Button>
      </ValidatedForm>
    </Modal>
  );
}
