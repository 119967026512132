import { withZod } from "@remix-validated-form/with-zod";
import { z } from "zod";

export const forgottenPasswordEmailSchema = z.object({
  recaptcha: z.string(),
  email: z
    .string()
    .regex(/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/, "L'email n'est pas au bon format.")
    .min(1, "Veuillez renseigner un email."),
});

export const forgottenPasswordEmailValidator = withZod(forgottenPasswordEmailSchema);
