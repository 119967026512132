import { withZod } from "@remix-validated-form/with-zod";
import { z } from "zod";

export const loginSchema = z.object({
  username: z
    .string()
    .min(1, "Veuillez saisir votre email.")
    .regex(
      /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/,
      "L'email n'est pas au bon format."
    ),
  password: z.string().min(1, "Veuillez saisir votre mot de passe."),
  stayConnected: z.string().optional(),
});

export const loginValidator = withZod(loginSchema);
